import types from './mutationTypes';
import {take} from 'lodash';

const setLanguage = (lang) => (lang === 'sr' ? 'sr-Latn' : lang);

export default {
  [types.SET_PARAMS](state, payload) {
    if (payload.lang) {
      // eslint-disable-next-line no-param-reassign
      payload.lang = setLanguage(payload.lang);
    }
    state.theme = payload.theme || 'dark';
    state.palette = payload.palette || 'default';
    state.isGateway = !!payload.gateway;
    state.queryParametars = payload;
  },
  [types.SET_CONFIG](state, payload) {
    state.config = payload;
    state.config.taxes = payload.rules.taxes;
    state.config.locale = setLanguage(state.config.locale);
  },
  [types.SET_OFFER](state, payload) {
    state.offer = take(payload, state.config.rules.maxNumEvents.value);
  },
  [types.SET_SCREEN_SIZE](state, payload) {
    state.screenSize = payload;
    state.isMinimizeVisible = (!state.isMobile && payload >= 660) && payload < 960;
  },
  [types.TOGGLE_AUDIO](state) {
    state.isAudioOn = !state.isAudioOn;
  },
  [types.SET_IS_VIDEO_UNAVAILABLE](state, payload) {
    state.isVideoUnavailable = payload;
  },
  [types.TOGGLE_INFO_MODAL](state) {
    state.infoModalActive = !state.infoModalActive;
  },
  [types.UPDATE_ACTIVE_EVENT](state, payload) {
    state.activeEventId = payload;
  },
  [types.SET_GAMES_INFO_ACTIVE](state, payload) {
    state.gamesInfoActive = payload;
  },
  [types.SET_IS_BETSLIP_OPEN](state, payload) {
    state.isBetslipOpen = payload;
  },
  [types.SET_IS_VISUALIZATION_READY](state, payload) {
    state.isVisualizationReady = payload;
  },
  [types.SET_ODD_TYPE](state, payload) {
    state.oddType = payload;
  },
  [types.SET_TP_TOKEN](state, payload) {
    state.tpToken = payload;
  },
  [types.SET_TIME_ZONE](state, payload) {
    state.timezone = payload;
  },
  [types.TOGGLE_TICKET_HISTORY](state, payload) {
    state.activeTicketHistory = payload;
  },
  [types.TOGGLE_HISTORY_LOADER](state, payload) {
    state.activeHistoryLoader = payload;
  },
  [types.SET_CURRENT_EVENT](state, payload) {
    state.currentEvent = payload;
  },
  [types.SET_INTERNET_CONNECTION](state, payload) {
    state.internetConnection = payload;
  },
  [types.SET_TERMINAL_USER](state, payload) {
    state.terminalUser = payload;
  },
  [types.SET_IOS_APP](state, payload) {
    state.isIosApp = payload;
  },
  [types.SET_SEVEN_DATA](state, payload) {
    state.sevenData = payload;
  },
  [types.SET_RGS_INTEGRATION](state, payload) {
    state.rgsIntegration = payload;
  },
  [types.USER_PUSHER_SUBSCRIBED](state, payload) {
    state.userPusherSubscribed = payload;
  },
};
