<template>
  <div class="template">
    <router-view
      class="router-view"
      :class="{
        'is-mobile-or-tablet': isMobileOrTablet,
      }"
    />
    <div
      class="switch-to-portret"
      :class="{
        'is-mobile-or-tablet': isMobileOrTablet,
      }"
    >
      <img
        src="../assets/images/switchToPortret.png"
        alt="switchToPortret"
      >
      <span>{{ store.getters.translation('switch_to_portret') }}</span>
    </div>
  </div>
</template>

<script setup>
import isMobile from 'ismobilejs';
import { useStore } from 'vuex';
import { computed } from 'vue';
import { useRoute } from 'vue-router';

const store = useStore();
const route = useRoute();
const isMobileOrTablet = computed(() => {
  return isMobile().phone || isMobile().tablet && !store.getters.isTerminal && !isSevenView.value;
});
const isSevenView = computed(() => {
  return route.name === 'ticketsHistory' || route.name === 'ticketDetails';
});
</script>

<style scoped lang="scss">
.template {
  height: 100%;
  .router-view {
    &.is-mobile-or-tablet {
      @media (orientation: landscape) {
        display: none;
      }
      @media screen and (width:768px) and (height: 764px) and (orientation: landscape) {
        display: flex !important;
      }
      @media screen and (width:768px) and (height: 756px) and (orientation: landscape) {
        display: flex !important;
      }
    }
  }
  .switch-to-portret {
    display: none;
    &.is-mobile-or-tablet {
      @media (orientation: landscape) {
        width: 100%;
        height: 100%;
        position: fixed;
        background-size: 100% 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        overflow: hidden;
        z-index: 1001;
        top: 0;
        left: 0;
        background-color: #233c50;
        background-image: linear-gradient(225deg, #46384d 0%, #2d3449 49.82%, #233c50 100%);
        padding: 0 16px;
        text-align: center;
        span {
          color: #fff;
          font-size: 20px;
        }
      }
      @media screen and (width:768px) and (height: 764px) and (orientation: landscape) {
        display: none !important;
      }
      @media screen and (width:768px) and (height: 756px) and (orientation: landscape) {
        display: none !important;
      }
      display: none;
    }
  }
}
</style>
